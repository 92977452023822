import React from "react";
import Img from "gatsby-image";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import EventListItem from "../components/ListItems/EventListItem";
import Breadcrumb from "../components/Breadcrumb";
import HtmlParser from "../components/HtmlParser";

const Event = ({data}) => {
    return (
        <Layout contentType={data.node.internal.type}>
            <Metas title={data.node.title}/>
            <section className="section page-content">
                <div className="container lg:max-w-screen-lg mx-auto px-4">
                    <Breadcrumb/>

                    <Title title={data.node.title} className="text-primary"/>

                    <div className="block infos mb-8">
                        <div className="flex items-center -mx-2">
                            <div className="flex-initial px-2">
                                <div className="inline-block p-1 bg-white bg-primary-light text-white text-2xl">
                                    {(data.node.dates.end && (data.node.dates.end > data.node.dates.start)) ? (
                                        <>
                                       <span className="start-date mr-2">
                                           <span className="label">Du&nbsp;</span>{data.node.dates.startDay}.<span className="font-semibold">{data.node.dates.startMonth}</span>
                                       </span>
                                            <span className="end-date">
                                           <span className="label">au&nbsp;</span>{data.node.dates.endDay}.<span className="font-semibold">{data.node.dates.endMonth}</span>
                                       </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="label">Le&nbsp;</span>{data.node.dates.startDay}.<span className="font-semibold">{data.node.dates.startMonth}</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            {data.node.emplacement && (
                                <div className="flex-initial px-2">
                                    <address className="inline-block text-primary-light not-italic">
                                        <i className="fas fa-map-marker-alt"><span className="hidden">Adresse</span></i>&nbsp;{data.node.emplacement}
                                    </address>
                                </div>
                            )}
                        </div>
                    </div>

                    {data.node.relationships.image && (
                        <div className="block figure text-center mb-8">
                            <Img
                                fluid={data.node.relationships.image.localFile.childImageSharp.fluid}
                                alt={data.node.title}
                            />
                        </div>
                    )}

                    {data.node.body && (
                        <div className="block content mb-8" data-typesense-field="body">
                            <HtmlParser html={data.node.body.value}/>
                        </div>
                    )}
                </div>
            </section>
            {data.otherEvents.length > 0 && (
                <section className="section page-content-suggestion">
                    <div className="container mx-auto px-4">
                        <h2 className="relative my-8 uppercase text-center text-primary-light strikethrough-primary-light">
                            <span className="relative inline-block px-4 bg-white">À lire aussi</span>
                        </h2>
                        <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {data.otherEvents.nodes.map((node, index) => (
                                <div key={index} className="column is-6 is-4-desktop">
                                    <EventListItem content={node}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </Layout>
    )
}

export default Event

export const query = graphql`
    query($internalId: Int!) {
        node: nodeEvenement(
            status: {eq: true},
            drupal_internal__nid: {eq: $internalId}
        ) {
            title
            body {
                value
            }
            dates: field_date {
                start: value(formatString: "X")
                startDay: value(formatString: "DD")
                startMonth: value(formatString: "MM")
                end: end_value(formatString: "X")
                endDay: end_value(formatString: "DD")
                endMonth: end_value(formatString: "MM")
            }
            emplacement: field_emplacement
            internal {
                type
            }
            relationships {
                image: field_image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 992, maxHeight: 425, cropFocus: CENTER, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        otherEvents: allNodeEvenement(
            sort: {
              fields: [field_date___value, field_date___end_value], 
              order: ASC
            },
            filter: {
              is_forthcoming: {eq: true}, 
              status: {eq: true},
              drupal_internal__nid: {ne: $internalId}
            }
        ) {
            nodes {
                title
                path {
                    alias
                }
                dates: field_date {
                    startDay: value(formatString: "DD")
                    startMonth: value(formatString: "MM")
                }
                body {
                    summary
                    value
                }
                emplacement: field_emplacement
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 315, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
