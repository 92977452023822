import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Truncate from "../Truncate";
import Img from "gatsby-image";
import Small16by10 from "../Images/Small16by10";

const EventListItem = ({content}) => (
    <div className="event-list-item card relative flex flex-col h-full">
        <div className="card-image relative flex-initial pl-4">
            <Link to={content.path.alias}>
                {content.relationships.image ? (
                    <Img
                        fluid={content.relationships.image.localFile.childImageSharp.fluid}
                        alt={content.title}
                    />
                ) : (
                    <Small16by10 alt={content.title}/>
                )}
            </Link>
        </div>
        <div className="card-content flex-1 p-4 bg-primary-light pt-32 -mt-28">
            <div className="inline-block p-1 mb-2 bg-white text-primary-light text-2xl uppercase">
                {content.dates.startDay}.<span className="font-semibold">{content.dates.startMonth}</span>
            </div>

            <div className="title h3 uppercase text-white hover:text-primary transition-all">
                <Link to={content.path.alias}>{content.title}</Link>
            </div>

            <Truncate
                className="content text-white text-sm"
                limit={120}
                html={(
                    content.body.summary.length > 0
                        ? content.body.summary
                        : content.body.value
                )}
            />
        </div>
    </div>
)

EventListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default EventListItem
